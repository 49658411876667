import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";

const NavBar = () => {
  const [isNavActive, setNav] = useState(false);

  const links = [
    {
      id: 1,
      link: "home",
    },
    {
      id: 2,
      link: "about",
    },
    /*{
      id: 3,
      link: "portfolio",
    },*/
    {
      id: 4,
      link: "experience",
    },
    {
      id: 5,
      link: "contact",
    },
  ];

  return (
    <div className="flex justify-between items-center w-full h-20 bg-gradient-to-r from-gray-950 via-black to-gray-950 text-white fixed px-5">
      <div>
        <Link to="home" smooth duration={700}>
          <h1 className="text-3xl cursor-pointer">AJ-Coding</h1>
        </Link>
      </div>

      <ul className="hidden md:flex">
        {links.map(({ id, link }) => (
          <li
            key={id}
            className="px-4 cursor-pointer capitalize font-bold text-gray-400 hover:scale-105 hover:text-gray-200 duration-200"
          >
            <Link to={link} smooth duration={700}>
              {link}
            </Link>
          </li>
        ))}
      </ul>

      <div
        onClick={() => setNav(!isNavActive)}
        className="cursor-pointer pr-3 z-10 text-gray-400 md:hidden"
      >
        {isNavActive ? <FaTimes size={30} /> : <FaBars size={30} />}
      </div>

      {isNavActive && (
        <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-700 text-gray-400">
          {links.map(({ id, link }) => (
            <li
              key={id}
              className="px-4 cursor-pointer capitalize py-6 text-4xl"
            >
              <Link
                onClick={() => setNav(!isNavActive)}
                to={link}
                smooth
                duration={700}
              >
                {link}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NavBar;
