import React from "react";
import ProfileImage from "../assets/profileImage1.jpg";
import { GoArrowRight } from "react-icons/go";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div
      name="home"
      className="min-h-screen w-full bg-gradient-to-b from-black via-black to-gray-800 flex justify-center items-center py-20"
    >
      <div className="max-w-screen-2xl mx-auto px-4 md:px-8 flex flex-col md:flex-row">
        <div className="md:flex-none">{/* Empty div for spacing */}</div>
        <div className="md:flex-grow md:order-1 md:mr-8">
          <div className="text-center md:text-left">
            <h2 className="text-3xl sm:text-7xl font-bold text-white">
              Ambroz Jagodic
            </h2>
            <h2 className="text-2xl sm:text-4xl font-bold text-white">
              Backend Developer
            </h2>
            <p className="text-gray-500 py-4 max-w-3xl text-sm sm:text-2xl">
              Welcome! I'm a passionate Backend Developer with a newfound
              interest for exploring the world of Frontend. With expertise in
              .NET Core development and a growing knowledge in frontend
              technologies, I thrive on turning ideas into functional and
              scalable solutions.
            </p>
            <div className="hidden md:block">
              {/* This button is displayed for larger screens */}
              <Link
                to="contact"
                smooth
                duration={700}
                className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
              >
                Contact me
                <span className="ml-1 group-hover:rotate-90 duration-300">
                  <GoArrowRight size={20} />
                </span>
              </Link>
            </div>
          </div>
          <div className="md:hidden flex flex-col items-center py-3">
            {/* This image container is displayed above the button for mobile screens */}
            <div
              className="rounded-full w-40 h-40"
              style={{
                backgroundImage: `url(${ProfileImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                margin: "auto",
              }}
            />
            {/* This button is displayed for mobile screens */}
            <Link
              to="contact"
              smooth
              duration={700}
              className="group text-white w-fit px-6 py-3 mt-5 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer md:hidden"
            >
              Contact me
              <span className="ml-1 group-hover:rotate-90 duration-300">
                <GoArrowRight size={20} />
              </span>
            </Link>
          </div>
        </div>
        <div className="md:flex-none md:order-2 flex items-center justify-center md:mr-0">
          {/* This image container is displayed to the right for larger screens */}
          <div className="hidden md:block md:flex md:flex-row items-center md:justify-start">
            <div
              className="rounded-full w-40 h-40 md:w-96 md:h-96"
              style={{
                backgroundImage: `url(${ProfileImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
