import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="w-full min-h-screen bg-gradient-to-b from-gray-800 to-black text-white flex items-center justify-center py-20"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full text-center sm:text-left">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>
        <p className="text-sm sm:text-xl">
          Welcome! I'm Ambroz Jagodic from Slovenia, a passionate Backend
          Developer with a background in electrical engineering. My hard work
          and high ambition are driving me in the pursuit of excellence in the
          world of software development.
        </p>
        <br />
        <p className="text-sm sm:text-xl">
          In 2019, I graduated with a Bachelor's degree in Electrical
          Engineering from the University of Ljubljana. Driven by my fascination
          with technology and innovation, I decided to continue the path of
          specialization, enrolling in the university's Master's program in
          Automation and Information Technology.
        </p>
        <br />
        <p className="text-sm sm:text-xl">
          On my academic pursuits, I discovered my passion and talent for
          programming in 2018. That is when I got my first job as a Junior
          Sowftware Developer and from that moment on, my life was forever
          transformed. I worked my way into the world of backend development,
          building my skills with .NET technologies. Embracing every challenge,
          I quickly evolved from a junior developer into a seasoned
          professional.
        </p>
        <br />
        <p className="text-sm sm:text-xl">
          Are you seeking an agile, innovative, and dependable Backend Developer
          with a natural affinity for problem-solving? I'm eager to collaborate
          on your next project.
        </p>
        <br />
        <p className="text-sm sm:text-xl font-semibold">
          Connect with me below and let's see what we can build together!
        </p>
      </div>
    </div>
  );
};

export default About;
